@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@300;500;700&family=Radio+Canada:wght@300&display=swap');

body {
    background-color: #FFF;
}

* {
    font-family: 'IBM Plex Sans Arabic', sans-serif;
}

#sidebar{
}
.text-decoration-none-hover:hover {
    text-decoration: none !important;
}
ul#article-side-buttons-bar > li:hover {
    background-color: #c4e6ec;
}

.tag_element:hover {
    color: #000 !important;
    background-color: #EEE;
    border: 1px #AAA solid !important;
    text-decoration: none !important;
}

a:hover {
    text-decoration: underline !important;
}
